import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);


export default new Vuex.Store({
    state: {
        user: null // 初始值为 null 或一个空对象
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
          },
          clearUser(state) {
            state.user = null;
          }
    },
    actions: {
        login({ commit }, user) {
            // 假设登录成功后调用这个方法
            commit('setUser', user);
          },
          logout({ commit }) {
            // 假设退出登录时调用这个方法
            commit('clearUser');
          }
    },
    getters: {
        user(state) {
            return state.user;
          }
    }
  });