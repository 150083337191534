<template>
  <div id="app">
    <router-view />
    <FloatingIcons />
  </div>
</template>

<script>
// 导入头部和尾部组件

import FloatingIcons from "./components/FloatingIcons.vue";

export default {
  name: "App",
  components: {
    FloatingIcons,
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style>
/* 全局样式定义，可以在这里定义应用的通用样式 */
.taga {
  padding: 5px 12px;
  color: #fff;
  margin: 5px;
  text-align: center;
  height: auto;
  /* border: 1px solid #4C4D56!important; 设置边框宽度为2px */
  border-radius: 20px !important; /* 设置边框弧度 */
  /* border-image: linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2)) 1!important;  */

  background-color: #1a1b2e; /* 背景为透明 */
  transition: background-color 0.3s; /* 添加过渡效果 */
  box-shadow: -1px 0px 3px #eaebec;
}

.hovered-taga .taga {
  /* border: 1px solid  rgba(255, 255, 255, 0.2)!important; 设置边框宽度为2px */
  border-radius: 20px !important; /* 设置边框弧度 */
  background-color: transparent; /* 背景为透明 */

  /* border-image: linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2))1 !important;  */

  color: black !important;
  font-weight: bold;
  opacity: 1;

  background-color: transparent; /* 设置边框宽度为2px */
}
.imgA img {
  max-width: 920px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .imgA img {
    max-width: 330px;
    margin: 0 auto;
  }
}
</style>
