import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'; // 引入你的 Vuex store 或者其他的状态管理器

Vue.use(VueRouter)

// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'App',
    component: () => import('../App.vue'),
    redirect: '/Home',  // 重定向到主页
    children: [
      { path: '403', name: 'NoAuth', meta: { name: '无权限' }, component: () => import('../views/manager/403') },
      { path: 'admin', name: 'Admin', meta: { name: '管理员信息' }, component: () => import('../views/manager/Admin') },
      { path: 'adminPerson', name: 'AdminPerson', meta: { name: '个人信息' }, component: () => import('../views/manager/AdminPerson') },
      { path: 'password', name: 'Password', meta: { name: '修改密码' }, component: () => import('../views/manager/Password') },
      { path: 'notice', name: 'Notice', meta: { name: '公告信息' }, component: () => import('../views/manager/Notice') },
      { path: 'business', name: 'Business', meta: { name: '教师信息' }, component: () => import('../views/manager/Business') },
      { path: 'yunpay', name: 'yunpay', meta: { name: '支付页面' }, component: () => import('../views/manager/yunpay') },
      { path: 'businessPerson', name: 'BusinessPerson', meta: { name: '教师个人信息' }, component: () => import('../views/manager/BusinessPerson') },
      { path: 'details', name: 'Details', meta: { name: '商品详情信息' }, component: () => import('../views/manager/Details') },
      { path: 'course', name: 'Course', meta: { name: '课程信息' }, component: () => import('../views/manager/Course') },
      { path: 'banner', name: 'Banner', meta: { name: '广告信息' }, component: () => import('../views/manager/Banner') },
      { path: 'agreement', name: 'Agreement', meta: { name: '用户协议' }, component: () => import('../views/manager/Agreement') },
      { path: 'gdsq', name: 'gdsq', meta: { name: '工单申请信息' }, component: () => import('../views/manager/gdsq') },
      { path: 'addgd', name: 'addgd', meta: { name: '添加工单' }, component: () => import('../views/manager/addgd') },
      { path: 'zc', name: 'zc', meta: { name: '组织管理' }, component: () => import('../views/manager/zc') },
      { path: 'car', name: 'car', meta: { name: '信息管理' }, component: () => import('../views/manager/Car') },

      { path: 'privacy', name: 'Privacy', meta: { name: '隐私信息' }, component: () => import('../views/manager/Privacy') },
      { path: 'comment', name: 'Comment', meta: { name: '课程评论信息' }, component: () => import('../views/manager/Comment') },
      { path: 'information', name: 'Information', meta: { name: '教师上传资料' }, component: () => import('../views/manager/Information') },
      { path: '/myInfo', name: 'MyInfo', meta: { name: '个人上传资料' }, component: () => import('../views/manager/MyInfo') },
      { path: 'info', name: 'info', meta: { name: '详细信息' }, component: () => import('../views/manager/info') }
    ]
  },
 
  { path: '/login', name: 'Login', meta: { name: '登录' }, component: () => import('../views/Login.vue') },
  { path: '/home', name: 'Home', meta: { name: '系统首页'}, component: () => import('../views/manager/Home') },

  { path: '*', name: 'NotFound', meta: { name: '无法访问' }, component: () => import('../views/404.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // 检查目标路由是否需要登录
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 这里假设你使用 Vuex 管理用户登录状态
    const isAuthenticated = localStorage.getItem("user");
    // console.log(store.getters.user)
    // console.log(this.$store.getters.user)
    // return
    if (isAuthenticated == null) {
      // 未登录，重定向到登录页面
      Vue.prototype.$message.error("请先登录!");
      next({
        path: '/login',
        query: { redirect: to.fullPath } // 保存用户本来要去的路径
      });
    } else {
      // 已登录，允许跳转
      next();
    }
  } else {
    // 不需要登录的页面，允许跳转
    next();
  }
});
export default router
