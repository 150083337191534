<template>
    <div class="floating-icons">
      <div class="icon-item" style="border-bottom: 1px solid #DDDDDD;" @click="consult">
        <img src="../assets/imgs/lx.png" alt="在线咨询" />
        <span style="color: #fff;font-size: 14px;">客服咨询</span>
        <span style="color: #fff;" class="font-szie1">(在线时间: 9-21点)</span>

      </div>
      <div class="icon-item" @click="gzh">
        <img src="../assets/imgs/gzh.png" alt="公众号" />
        <span style="color: #fff;font-size: 14px;">公众号</span>
      </div>
      <div class="icon-item" v-if="showBackToTop" style="margin-top: 20px;" @click="scrollToTop">
        <img src="../assets/imgs/top.png" alt="回到顶部" />
        <span style="color: #fff;font-size: 14px;">返到顶部</span>
      </div>


      <el-dialog 
      
      title="长按图片或扫码关注"
      :visible.sync="show"
      width="30%"
      center
      :style="{ width: dialogWidth }"
  >
    
      <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;">

         <div >
          <!-- <div ref="qrcode"  ></div> -->
              <img src="../assets/imgs/ewm.jpg" style="width: 200px;height: 200px;">
         </div>
    
         <div>
          <div>以便接收密码变更，续费提醒，优惠信息等等

          </div>
         </div>

         <div style="color: red;cursor: pointer;margin-top: 10px;font-size: 16px;" @click="show=false"> 
          确认
         </div>
    
      </div>
    
    </el-dialog>
    </div>
  </template>
  
  <script>
  export default {
    data() {
    return {
        show:false,
      showBackToTop: false // 控制“回到顶部”图标的显示和隐藏
    };
  },
    methods: {
        gzh (){
          this.show = true
        },
        consult (){
            window.open('https://work.weixin.qq.com/kfid/kfcb7e5e73d7e3af8dc', '_blank');
        },
        handleScroll() {
      // 如果页面滚动超过100px，显示“回到顶部”按钮
      this.showBackToTop = window.scrollY > 100;
    },
      scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    },
    mounted() {
    // 监听页面的滚动事件
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    // 组件销毁前移除事件监听
    window.removeEventListener('scroll', this.handleScroll);
  }

  };
  </script>
  
  <style scoped>
    @media (max-width: 600px) {
      .font-szie1{
    font-size: 7px!important;
  }
  .floating-icons {
    bottom: 10px;
    right: 10px;
  }
  >>>.el-dialog {
    width:70% !important;
  }
  .icon-item img {
    width: 19.8px;
    height: 19.8px;
  }
  .icon-item {  
     width: 66px!important;
     padding-top: 10px;
     text-align: center;
    cursor: pointer;
    padding-bottom: 10px;
    background-color: #2B2456!important;
  }
  
  .icon-item span {
    font-size: 12px;
  }
}
  .floating-icons {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 99999;
  }
 >>>.v-modal{
    background: red!important;
  }
.v-modal{
  background: #fff!important;

}
  .icon-item {  
     width: 106px;
     padding-top: 10px;
     text-align: center;
    cursor: pointer;
    padding-bottom: 10px;
    background-color: #2B2456!important;
  }
  
  .icon-item img {
    width: 26.4px;
    height: 26.4px;
  }

  .icon-item span {
    display: block;
    margin-top: 5px;
    font-size: 12px;
    color: #808080;
  }
  </style>
  